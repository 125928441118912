<template>
    <div class="online">
        <div class="section section1 wp100">
            <div class="secText">
                <div class="f48 text-blue fw700" style="margin-bottom:40px">线上点单</div>
                <div class="f24 fw700" style="margin-bottom:40px">线上线下一体化解决方案</div>
                <div>线上平台主要提供零售企业电商转型，会员线上线下一体化运营，线下门店自助购物; 餐饮门店智慧点餐等解决方案。结合线上平台产品方案，助推企业数字化管理，提高门店整体销售额、提升企业竞争力。</div>
            </div>
        </div>
        <div class="section section2 wp100 pd100">
            <div class="auto">
                <div class="f48" style="margin-bottom:50px">线上商城</div>
                <div>云蓝线上点单是基于传统零售门店，为零售商家搭建线上平台</div>
                <div> 助力商家线上卖货、数字营销、分销裂变、进销存统一、会员权益统一等,</div>
                <div> 其目的主要是为企业实现线上线下一体化运营管理的解决方案</div>
            </div>
            <div class="process">
            </div>
        </div>
        <div class="section section3 pd100">
            <div class="auto">
                <div class="f48">系统特色功能</div>
            </div>
            <div class="wd1200 flex align-center" style="margin-top:60px">
                <div class="lf flex justify-between align-center" style="background-color:#f2f6fb">
                    <img src="../../../assets/images/product/onlineClick/Group 91.png" alt="" srcset="">
                </div>
                <div class="rt flex align-center" style="background-color:#fff">
                    <div style="width:410px">
                        <div class="f36 text-blue" style="margin-bottom:40px">
                            赋能门店多场景卖货
                        </div>
                        <div class="f18">
                            打破传统实体门店单一的销售模式，为门店打造线下门店+线上商城多场景销售场景，多样化的销售场景满足不同顾客消费习惯，从而提高订单量实现盈收。
                        </div>
                    </div>
                </div>
            </div>
            <div class="wd1200 flex align-center" style="margin-top:60px">
                <div class="rt flex align-center" style="background-color:#fff">
                    <div style="width:410px">
                        <div class="f36 text-blue" style="margin-bottom:40px">
                            装修简单 办公高效
                        </div>
                        <div class="f18">
                            线上点单装修方法简单，几十种组件拖拽式操作，效果实时可视化；企业管理商城可用来购手机后台进行随时管理；支持云打印，实时提醒来单并打印，不错过每一份订单。
                        </div>
                    </div>
                </div>
                <div class="lf flex justify-between align-center" style="background-color:#f2f6fb">
                    <img src="../../../assets/images/product/onlineClick/Group 92.png" alt="" srcset="">
                </div>
            </div>
            <div class="wd1200 flex align-center" style="margin-top:60px">
                <div class="lf flex justify-between align-center" style="background-color:#f2f6fb">
                    <img src="../../../assets/images/product/onlineClick/Group 93.png" alt="" srcset="">
                </div>
                <div class="rt flex align-center" style="background-color:#fff">
                    <div style="width:410px">
                        <div class="f36 text-blue" style="margin-bottom:40px">
                            统一品牌管理运营
                        </div>
                        <div class="f18">
                            线上线下商品、会员、数据、进销存、营销互通，实现无缝对接。数据统一于企业可以更好的掌握整体运营状况，于会员可以统一会员权益得到更好的服务。
                        </div>
                    </div>
                </div>
            </div>
            <div class="wd1200 flex align-center" style="margin-top:60px">
                <div class="rt flex align-center" style="background-color:#fff">
                    <div style="width:410px">
                        <div class="f36 text-blue" style="margin-bottom:40px">
                            营销丰富 吸引客流
                        </div>
                        <div class="f18">
                           云蓝线上有多种丰富的促销方案，方案适用于各类客户群体各种门店活动需求。基于后台数据能有效精准营销和触达会员，提高活动转化率。
                        </div>
                    </div>
                </div>
                <div class="lf flex justify-between align-center" style="background-color:#f2f6fb">
                    <img src="../../../assets/images/product/onlineClick/Group 94.png" alt="" srcset="">
                </div>
            </div>
            <div class="wd1200 flex align-center" style="margin-top:60px">
                <div class="lf flex justify-between align-center" style="background-color:#f2f6fb">
                    <img src="../../../assets/images/product/onlineClick/Group 95.png" alt="" srcset="">
                </div>
                <div class="rt flex align-center" style="background-color:#fff">
                    <div style="width:410px">
                        <div class="f36 text-blue" style="margin-bottom:40px">
                            推客分销 无限裂变
                        </div>
                        <div class="f18">
                            云蓝推客分销，无限分销裂变，发展人人都是销售员，提高门店销量。完整健全的分销体系，助力商家全场景营销。
                        </div>
                    </div>
                </div>
            </div>
            <div class="wd1200 flex align-center" style="margin-top:60px">
                <div class="rt flex align-center" style="background-color:#fff">
                    <div style="width:410px">
                        <div class="f36 text-blue" style="margin-bottom:40px">
                            提高企业服务质量
                        </div>
                        <div class="f18">
                            用户用小程序商城购物只要微信授权一键登录即可；搜索商品自动关联出商品全名称，快速选择；快递物流实时更新；在线下消费也能实时收到消费通知。
                        </div>
                    </div>
                </div>
                <div class="lf flex justify-between align-center" style="background-color:#f2f6fb">
                    <img src="../../../assets/images/product/onlineClick/Group 96.png" alt="" srcset="">
                </div>
            </div>
        </div>
        <div class="section section4 pt100">
        <div class="auto">
            <div class="f36" style="color:#fff">扫码体验云蓝线上点单小程序</div>
        </div>
        <div class="code">
            <img src="../../../assets/images/download/saomaCode.jpg" alt="" srcset="" style="width:200px;height:200px">
        </div>
    </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.online{
    width: 100%;
}
.section1{
    height: 595px;
    background-image: url('../../../assets/images/product/onlineClick/Group 77.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.secText{
    width: 600px;
    height: 260px;
    position: absolute;
    top: 173px;
    left: 359px;
}
.section2{
    height: 820px;
    box-sizing: border-box;
}
.auto{
    width: 500px;
    margin: 0 auto;
    text-align: center;
}
.process{
    width: 1200px;
    height: 406px;
    margin: 0 auto;
    background-image: url('../../../assets/images/product/onlineClick/Group 78.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 40px;
}
.section3{
    height: 3215px;
    background-color: #f7f9fa;
    box-sizing: border-box;
}
.wd1200{
    width: 1200px;
    margin: 0 auto;
}
.lf,.rt{
    width: 600px;
    height: 439px;
    justify-content: center;
}
.lf img,.rt img{
    width: 421px;
    height: 369px;
}
.section4 {
  width: 100%;
  height: 529px;
  box-sizing: border-box;
  background-image: url("../../../assets/images/product/onlineClick/Group66.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.code{
    width: 100%;
    text-align: center;
    margin-top: 60px;
}
</style>